import React from 'react';
import styles from './footer.module.css';
import { FOOTER_TEXT_1 } from '../../../Constants/index';

const Footer = () => (
  <div data-testid="footer" className={`${styles.container} center`}>
    <p className={`footer_text`}>{FOOTER_TEXT_1}</p>
  </div>
);

export default Footer;
